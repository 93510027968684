import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import qs from 'qs'

class ProductOrder {
  constructor () {
    const base = axios.create({
      // using base items api
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.productOrder = base
  }

  async getProductOrder (party_id, params = {}) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_order`,
      method: 'GET',
      params: { party_id, ...params }
    })
  }

  async getProductOrders (params) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_orders`,
      method: 'GET',
      params: { ...params },
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async createProductOrder (data) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_order`,
      method: 'POST',
      data
    })
  }

  async updateProductOrder (product_order_id, party_id, product_event_id, submitted) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_order/${product_order_id}`,
      method: 'PUT',
      data: { party_id, product_event_id, submitted }
    })
  }

  async deleteProductOrder (product_order_id) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_order/${product_order_id}`,
      method: 'DELETE'
    })
  }

  async getProductOrderItems (product_order_id, offset = 0, limit = 500) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_order_items/${product_order_id}`,
      method: 'get',
      params: { offset, limit }
    })
  }

  async getProductOrderItem (product_order_item_id) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_order_item/${product_order_item_id}`,
      method: 'get'
    })
  }

  async createProductOrderItem (product_order_item) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_order_item`,
      method: 'POST',
      data: product_order_item
    })
  }

  async updateProductOrderItem (product_order_item_id, product_order_item) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_order_item/${product_order_item_id}`,
      method: 'PUT',
      data: product_order_item
    })
  }

  async deleteProductOrderItem (product_order_item_id) {
    await AuthToken.setAuth(this.productOrder)
    return this.productOrder({
      url: `/product_order_item/${product_order_item_id}`,
      method: 'DELETE'
    })
  }
}

export default new ProductOrder()